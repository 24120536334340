import {
  CURRENCY,
  ENDPOINT,
  ERROR_CODES,
  LOCAL_STORAGE_KEYS,
  WEBSITE_LIMIT_STATUSES,
  SUPPORT_EMAIL,
  WEBSITE_CUSTOM_EVENTS,
  ACCOUNT_TYPES,
  URLS,
  JOIN_KIDSACADEMY_FOR_FREE_MODAL_TYPE,
  HTTPS_PREFIX,
  GET_PARAMS,
  PLACEMENTS,
  WORKSHEET_ACTIONS,
  WORKSHEET_ACTION_TYPES,
  IS_DOWNLOAD_WORKSHEET_FLOW,
  MODALS
} from './config.js';

export const getBrowser = () => {
  const userAgent = navigator.userAgent;
  let tem;
  let M = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return 'IE ' + (tem[1] || '');
  }

  if (M[1] === 'Chrome') {
    tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = userAgent.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
};

export const toggleButtonLoading = ($button, isLoading, callback = () => {}) => {
  if (!$button) return;
  if (!$button.length) return;

  const width = $button.width();

  if (isLoading) {
    $button.attr('data-value', $button.html());
    $button.attr('data-position', $button.css('position'));
    $button.attr('disabled', true);
    $button.css('position', 'relative');
    // eslint-disable-next-line max-len
    $button.html('<div class="lds-ellipsis" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"><div></div><div></div><div></div><div></div></div>');
    $button.width(width);
  } else {
    $button[0].style.removeProperty('width');
    $button.html($button.attr('data-value'));
    $button.css('position', $button.attr('data-position'));
    $button.attr('disabled', false);
  }
  callback();
};

export const capitalize = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const replaceEmailWithLink = (error, addLinkToEmail = true) => {
  const emailLinkHref = `mailto:${SUPPORT_EMAIL}`;
  const text1 = 'contact us';
  const text2 = 'contact support';

  // TODO add support for spanish lang

  if (error.indexOf('@') > -1 && addLinkToEmail) {
    const email = error.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    return error.replace(email[0], `<a href="mailto:${email[0]}">${email[0]}</a>`);
  }

  if (error.toLowerCase().indexOf(text1) > -1 && addLinkToEmail) {
    return error.replace(text1, `<a href="${emailLinkHref}">${text1}</a>`);
  }

  if (error.toLowerCase().indexOf(text2) > -1 && addLinkToEmail) {
    return error.replace(text2, `<a href="${emailLinkHref}">${text2}</a>`);
  }

  return error;
};

export const getError = (errorResponse, addLinkToEmail = true) => {
  const emailLinkHref = `mailto:${SUPPORT_EMAIL}`;

  const errorMsgs = {
    en: `Something went wrong. Please <a href="${emailLinkHref}">contact us</a>.`,
    es: `Algo salió mal. Por favor  <a href="${emailLinkHref}">contáctenos</a>.`
  };

  let defaultErrorMsg = errorMsgs[window.LANGUAGE_CODE];
  if (!defaultErrorMsg) defaultErrorMsg = errorMsgs.en;

  if (!errorResponse) return defaultErrorMsg;

  let errors = '';

  try {
    errors = JSON.parse(errorResponse.responseText);
  } catch (e) {
    return defaultErrorMsg;
  }

  if (!errors) return defaultErrorMsg;
  if (!errors.error) return defaultErrorMsg;

  const updatedErrorText = replaceEmailWithLink(errors.error, addLinkToEmail);
  return updatedErrorText;
};

export const getErrorCode = (errorResponse) => {
  if (!errorResponse) return null;
  let error = null;

  try {
    error = JSON.parse(errorResponse.responseText);
  } catch (e) {
    return null;
  }

  if (!error) return null;
  if (!error.error_code) return null;

  return error.error_code;
};

export const setUrlParam = (name, value) => {
  if (history.pushState) {
    const location = window.location;
    const newUrl = `${location.protocol}//${location.host}${location.pathname}?${name}=${value}`;
    window.history.pushState({path: newUrl}, '', newUrl);
  }
};

export const getUrlParam = (paramName) => {
  if (typeof URLSearchParams === 'function') {
    const paramValue = new URLSearchParams(window.location.search).get(paramName);
    return paramValue;
  }

  // fallback for IE
  const results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (results == null) return null;
  return decodeURI(results[1]) || null;
};

export const deleteUrlParam = (paramName) => {
  const url = new URL(window.location);
  url.searchParams.delete(paramName);
  history.replaceState(null, null, url);
};

export const isBase64 = (str) => {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

export const fixPlusSymbolInProvidedEmail = (email) => {
  return email.replace(' ', '+');
};

export const scrollToHashedElement = (hash, {
  callback = () => {},
  scrollIntoViewDelay = 500,
  scrollTimeout = 300
}) => {
  if (!hash) return;

  const $header = $('header');
  const isHeaderFixed = $header.length ? document.documentElement.clientWidth > 891 : false;
  const heightToSubtract = isHeaderFixed ? $header.innerHeight() : 0;

  setTimeout(() => {
    const formattedHash = hash.substr(1);
    const $elem = $(`[data-hash="${formattedHash}"]`);

    if ($elem.length) {
      //need to subtract header height cause header for tablets is fixed
      const elemOffset = $elem.offset().top;
      const scrollTop = elemOffset - heightToSubtract;

      $([document.documentElement, document.body]).animate(
        {scrollTop},
        scrollIntoViewDelay,
        () => {
          callback($elem);
        }
      );
    }
  }, scrollTimeout);
};

/*
*  func param takes name of needed api function, e.g. 'playVideo'
*  For a full list of possible functions, see:
*  http://code.google.com/apis/youtube/js_api_reference.html
*/
export const callYTPlayer = ($frame, func, args) => {
  if (!($frame instanceof jQuery)) return;
  const frameContainerId = $frame.attr('id');
  const iframe = $frame[0];
  if (iframe) {
    // Frame exists,
    iframe.contentWindow.postMessage(JSON.stringify({
      'event': 'command',
      'func': func,
      'args': args || [],
      'id': frameContainerId
    }), '*');
  }
};

export const getYTVideoConfig = (videoId, onReadyHandler, $videoModal) => {
  return {
    width: '100%',
    height: '300',
    videoId: videoId,
    playerVars: {
      rel: 0
    },
    events: {
      'onReady': onReadyHandler,
      'onStateChange': function (state) {
        if (state.data === window.YT.PlayerState.ENDED && $videoModal) {
          $videoModal.modal('hide');
        }
      }
    }
  };
};

export const initGoogleRecaptcha = (callback, expiredCallback = null) => {
  if (!$('#google-recaptcha-container').length) return;

  // add RECAPTCHA script tag to DOM
  $(document).ready(() => {
    const captchaDataSelector = 'google-recaptcha';
    const $captchaDataSelector = $(`[data-selector="${captchaDataSelector}"]`);
    if ($captchaDataSelector.length) return; // prevent multiple recaptcha init

    window.onRECAPTCHAloadCallback = () => {
      try {
        grecaptcha.render('google-recaptcha-container', {
          'sitekey': window.RECAPTCHA_SITE_KEY,
          'callback': callback,
          'expired-callback': expiredCallback,
          'error-callback': (error) => {
            console.error(error); // eslint-disable-line no-console
          },
        });
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    };

    // create script tag
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=onRECAPTCHAloadCallback&render=explicit');
    script.setAttribute('data-selector', captchaDataSelector);

    // set size depends on device screen width
    const deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    const minAvailableWidthForDesktopView = 460;
    if (deviceWidth < minAvailableWidthForDesktopView) {
      $('#google-recaptcha-container').attr('data-size', 'compact');
    }

    document.body.appendChild(script);
  });
};

export const handleCAPTCHAError = (errorResponse) => {
  if (!errorResponse) return;

  let error = {};

  try {
    error = JSON.parse(errorResponse.responseText);
  } catch (e) {
    return;
  }

  if (!error) return;

  if (error.error_code === ERROR_CODES.CAPTCHA_INVALID
    && grecaptcha
    && typeof grecaptcha.reset == 'function') {
    grecaptcha.reset();
  }
};

export const copyStringToBuffer = (stringToCopy) => {
  if (!stringToCopy) return;

  const el = document.createElement('textarea');

  el.value = stringToCopy;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);

  const selected = document.getSelection().rangeCount > 0
    ? document.getSelection().getRangeAt(0) : false;

  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

export const isASCII = (str) => {
  return /^[\x00-\x7F]*$/.test(str);
};

export const braintreeDropinConfig = (totalAmount, container = '#dropin-container') => {
  const config = {
    authorization: window.braintree_token,
    container: container,
    threeDSecure: true,
    vaultManager: true,
    card: {
      cardholderName: {
        required: true
      },
      overrides: {
        styles: {
          '.invalid': {
            color: 'red'
          }
        }
      }
    },
    paypal: {
      flow: 'vault',
      buttonStyle: {
        size: 'medium'
      }
    },
    paypalCredit: {
      flow: 'vault',
      buttonStyle: {
        size: 'medium'
      }
    },
    venmo: {},
    applePay: {
      displayName: 'Kids Academy Company',
      paymentRequest: {
        total: {
          label: 'Kids Academy Talented & Gifted',
          amount: totalAmount // should be in dollars (string): '152.99', '0.99'
        },
        // We recommend collecting billing address information, at minimum
        // billing postal code, and passing that billing postal code with all
        // Google Pay transactions as a best practice.
        requiredBillingContactFields: []
      },
    },
  };

  if (window.ENV !== 'prod') {
    config.googlePay = {
      googlePayVersion: 2,
      merchantId: '09638095500891200480',
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPrice: totalAmount, // should be in dollars (string): '152.99', '0.99'
        currencyCode: 'USD'
      },
      allowedPaymentMethods: [{
        type: 'CARD',
        parameters: {
          // We recommend collecting and passing billing address information
          // with all Google Pay transactions as a best practice.
          billingAddressRequired: true,
          billingAddressParameters: {
            format: 'FULL'
          }
        }
      }]
    };
  }

  return config;
};

export const threeDSecureConfig = (totalPrice) => {
  const givenName = window.user && window.user.first_name;
  const surname = window.user && window.user.last_name;
  return {
    amount: parseFloat(totalPrice),
    email: window.user && window.user.email,
    billingAddress: {
      // ASCII-printable characters required, else will throw a validation error
      givenName: isASCII(givenName) && givenName,
      // ASCII-printable characters required, else will throw a validation error
      surname: isASCII(surname) && surname
    }
  };
};

export const getUserSources = (key) => {
  if (!key) return '';
  if (!window.sourcesOfUser) return '';
  if (!window.sourcesOfUser.hasOwnProperty(key)) return '';

  const result = window.sourcesOfUser[key];
  if (result === 'None') return null;

  return result;
};

export const scrollToTop = () => {
  const $body = $('body, html');
  $body.animate({
    scrollTop: 0
  }, 500);
};

export const getHumanReadablePrice = (priceInCents) => {
  const result = (priceInCents / 100).toFixed(2).toString();
  return `${CURRENCY}${result}`;
};


export const isUiTestingMode = () => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_UI_TESTING_MODE);
  const result = value === 'true';

  return result;
};

export const isAnySelected = ($elementsArray) => {
  let isAnySelected = false;

  $elementsArray.each(function (index, item) {
    if (item.checked) isAnySelected = true;
  });

  return isAnySelected;
};


export const isObjectEmpty = (obj) => {
  if (Object.getOwnPropertyNames) {
    return (Object.getOwnPropertyNames(obj).length === 0);
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }

  return true;
};

export const isBrowserSupportsWebp = (callback) => {
  const imgHeight = 2;
  const webP = new Image();
  /* eslint-disable max-len */
  webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';

  webP.onload = webP.onerror = () => {
    callback(webP.height === imgHeight);
  };
};

export const dispatchCustomEvent = (name, data) => {
  const event = new CustomEvent(name, {detail: data});
  document.dispatchEvent(event);
};

export const storage = {
  set: (key, value) => {
    localStorage.setItem(key, value);
  },
  get: (key, isDelete = false) => {
    const result = localStorage.getItem(key);
    if (isDelete) localStorage.removeItem(key);
    return result;
  },
  delete: (key) => {
    localStorage.removeItem(key);
  }
};

export const isFormValid = ($form) => {
  const formParsley = $form.parsley();
  formParsley.validate();

  return formParsley.isValid();
};

export const deleteGetParamFromUrl = (param) => {
  const regex = new RegExp(`[\\?&]${param}=[^&]+`);

  history.replaceState && history.replaceState(
    // eslint-disable-next-line max-len
    null, '', location.pathname + location.search.replace(regex, '').replace(/^&/, '?') + location.hash
  );
};

/**
 * @param date
 * @returns {string} month dd, yyyy // example - June 1, 2020
 */
export const formatDate = (date) => {
  /* eslint-disable max-len */
  const formattedDate = new Date(date).toLocaleDateString({}, {
    timeZone: 'UTC',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
  return formattedDate;
};

export const isThreeDSecureCanceled = (payload) => {
  const {type, liabilityShifted: isThreeDSecurePassed} = payload;
  return type === 'CreditCard' && !isThreeDSecurePassed;
};

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 */
export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const toggleStepProgressBar = ($stepProgressBar, callback = () => {}) => {
  if (storage.get(LOCAL_STORAGE_KEYS.IS_SHOW_STEP_PROGRESS_BAR) === 'True') {
    $stepProgressBar.show();
  } else {
    $stepProgressBar.hide();
  }

  callback();
};

export const processingPlurals = (number = null, singular = '', plural = '') => {
  if (number === null) return singular;
  const numberNum = parseInt(number);

  if (numberNum === 0) return plural;
  if (numberNum === 1) return singular;
  if (numberNum > 1) return plural;
};

export const initWorksheetDownload = (pdfLink) => {
  ga('send', 'event', 'PDF worksheet', 'download');

  const isSafariBrowser = getBrowser().toLowerCase().includes('safari');

  if (isSafariBrowser) {
    window.location = pdfLink;

    return;
  }

  window.open(pdfLink, '_blank');
};

export const toggleMarkWorksheetAsDone = (worksheetId, isPostMethod = true, callback = () => {}) => {
  $.ajax({
    url: ENDPOINT.MARK_AS_DONE(worksheetId),
    method: isPostMethod ? 'POST' : 'DELETE'
  }).then(() => {
    callback();
  });
};

export const toggleAddWorksheetToFavorite = (worksheetId, isPostMethod = true, callback = () => {}) => {
  $.ajax({
    url: ENDPOINT.ADD_TO_FAVORITE(worksheetId),
    method: isPostMethod ? 'POST' : 'DELETE'
  }).then(() => {
    callback();
  });
};

export const handlePdfDownload = (startPdfDownload = () => {}) => {
  $.ajax({
      url: ENDPOINT.WORKSHEETS_PDF_STATUS,
      method: 'GET'
    }).then((response) => {
      switch(response.can_download_pdf) {
        case WEBSITE_LIMIT_STATUSES.SHOW_PROMO_BANNER:   // cause of KAWD-209 we do not get this status no more
          // const $monetizationModal = $(MODALS.MONETIZATION);
          // $monetizationModal.modal('show');

          startPdfDownload();
          break;
        case WEBSITE_LIMIT_STATUSES.DENIED:
          dispatchCustomEvent(WEBSITE_CUSTOM_EVENTS.SHOW_PLANS_POPUP_NO_FREE);
          break;
        case WEBSITE_LIMIT_STATUSES.ALLOWED:
          startPdfDownload();
          break;
      }
    }, (error) => {
      // eslint-disable-next-line no-console
      console.error('Worksheet download status check request is failed', error);
    });
};

export const handleCompleteOnline = (goToCompleteOnlineInTgc = () => {}) => {
  $.ajax({
      url: ENDPOINT.WORKSHEET_CHECK_STATUS,
      method: 'GET'
    }).then((response) => {
      switch(response.can_download_pdf) {
        case WEBSITE_LIMIT_STATUSES.SHOW_PROMO_BANNER:  // cause of KAWD-209 we do not get this status no more
          // const $monetizationModal = $(MODALS.MONETIZATION);
          // $monetizationModal.modal('show');

          goToCompleteOnlineInTgc();
          break;
        case WEBSITE_LIMIT_STATUSES.DENIED:
          dispatchCustomEvent(WEBSITE_CUSTOM_EVENTS.SHOW_PLANS_POPUP_NO_FREE);
          break;
        case WEBSITE_LIMIT_STATUSES.ALLOWED:
          goToCompleteOnlineInTgc();
          break;
      }
    }, (error) => {
      // eslint-disable-next-line no-console
      console.error('Worksheet download status check request is failed', error);
    });
};

export const redirectToCompleteOnlineInTGC = (slug, queryParams) => {
  if (!slug) return;
  const baseUrl = `/worksheets-check/${slug}/?${GET_PARAMS.REDIRECT_TO_TGC}=true&${GET_PARAMS.SHOW_TGC_ONBOARDING}=true`;
  window.location = `${baseUrl}${queryParams ? `&${queryParams}` : ''}`;
};

export const getPageUrlName = () => {
  const result = $('body').attr('data-url');
  if (result) return result;

  return null;
};

export const addActivityToClassroom = ({
  activities = [],
  utm
}) => {
  const searchParams = new URLSearchParams({
    activities: JSON.stringify(activities),
  });

  // remove search params with empty string in value
  const keysForDel = [];
  searchParams.forEach((value, key) => {if (value === '') keysForDel.push(key);});
  keysForDel.forEach(key => {searchParams.delete(key);});

  let params = searchParams.toString() ? `?${searchParams.toString()}` : '';
  if (utm) params = `${params}&${utm}`;

  // TODO use window.COM_DOMAIN_NAME after Andrey set kidsacademy.com as default domain for .com instead of cr.kidsacademy.com
  let domain = 'https://www.kidsacademy.com/'; // prod
  const env = window.ENV ? window.ENV : window.boot.ENV;
  if (env === 'stage') domain = 'https://www.kad-staging-com.net/';
  if (env === 'dev') domain = 'http://localhost:8083/';

  window.location.href = `${domain}classroom/${params}`;
};

export const createClassroom = ({utm = ''}) => {
  // TODO use window.COM_DOMAIN_NAME after Andrey set kidsacademy.com as default domain for .com instead of cr.kidsacademy.com
  let domain = 'https://www.kidsacademy.com/'; // prod
  const env = window.ENV ? window.ENV : window.boot.ENV;
  if (env === 'stage') domain = 'https://www.kad-staging-com.net/';
  if (env === 'dev') domain = 'http://localhost:8083/';

  window.location.href = `${domain}classroom/${utm ? utm : ''}`;
};

export const showJoinKidsAcademyModal = ({
  type = JOIN_KIDSACADEMY_FOR_FREE_MODAL_TYPE.DEFAULT,
  checkStorage = true,
}) => {
  const modalWasShown = JSON.parse(localStorage
    .getItem(LOCAL_STORAGE_KEYS.JOIN_KIDSACADEMY_FOR_FREE));

  if (modalWasShown && checkStorage) return;

  const analyticEventType = type === JOIN_KIDSACADEMY_FOR_FREE_MODAL_TYPE.DEFAULT
    ? 'Init_PopUp_KA_COM' : 'ComleteOnline_PopUp';

  ga('send', 'event', analyticEventType, 'Show', 'Load');

  const $joinKidsacademyForFreeModal = $('[data-selector="join-kidsacademy-for-free-modal"]');

  const teacher = $('[data-selector="join-kidsacademy-teacher"]');
  const parent = $('[data-selector="join-kidsacademy-parent"]');
  const student = $('[data-selector="join-kidsacademy-student"]');

  $($joinKidsacademyForFreeModal).on('hidden.bs.modal', () => {
    ga('send', 'event', analyticEventType, 'Close', 'Click');
  });

  $(teacher).on('click', () => {
    ga('send', 'event', analyticEventType, '1-Teacher', 'Click');
    $joinKidsacademyForFreeModal.modal('hide');
  });

  $(parent).on('click', () => {
    ga('send', 'event', analyticEventType, '2-Parent', 'Click');
    $joinKidsacademyForFreeModal.modal('hide');
  });

  $(student).on('click', () => {
    ga('send', 'event', analyticEventType, '3-Student', 'Click');
    $joinKidsacademyForFreeModal.modal('hide');
  });

  $joinKidsacademyForFreeModal.modal('show');

  localStorage.setItem(
    LOCAL_STORAGE_KEYS.JOIN_KIDSACADEMY_FOR_FREE,
    JSON.stringify({isFinished: true})
  );
};

export const completeOnlineButtonHandler = ({ event, classroomUtms = {}, urlHandler = URLS.WORKSHEET_CHECK }) => {
  const target = $(event.currentTarget);
  const slug = target.attr('data-slug');
  const gradeId = target.attr('data-grade-id');
  const categorySlug = target.attr('data-category-slug');
  const activityId = target.attr('data-activity-id');
  const activityContentType = target.attr('data-activity-content-type');

  // not auth > show modal
  if (!window.isAuthenticated) {
    event.preventDefault();
    showJoinKidsAcademyModal({ type: JOIN_KIDSACADEMY_FOR_FREE_MODAL_TYPE.COMPLETE_ONLINE, checkStorage: false });
    return;
  }

  // NO subscription teacher > classroom
  if (window.accountType === ACCOUNT_TYPES.SCHOOL_ADMIN && window.lmsSubscriptionStatus !== 0) {
    event.preventDefault();
    addActivityToClassroom({
      activities: [{ categorySlug, gradeId, activityId, activityContentType }],
      utmSource: classroomUtms ? classroomUtms.utmSource : '',
      utmMedium: classroomUtms ? classroomUtms.utmMedium : '',
      utmContent: classroomUtms ? classroomUtms.utmContent : '',
    });
    return;
  }

  // NO subscription NOT teacher > .com/tg
  if (window.accountType !== ACCOUNT_TYPES.SCHOOL_ADMIN && !window.hasValidParentSubscription) {
    event.preventDefault();
    window.location = HTTPS_PREFIX() + window.COM_DOMAIN_NAME + URLS.TG_ONLINE;
    return;
  }

  // user with subscription
  if (window.hasValidParentSubscription || window.lmsSubscriptionStatus === 0) {
    event.preventDefault();
    window.location = urlHandler(slug);
  }
};

export const redirectToTgcWithOpenWorksheetParams = ({worksheetId, utm, skipOnboarding = true}) => {
  const page = `${URLS.TG_ONLINE}`;
  const params = worksheetId && `?${GET_PARAMS.OPEN_ACTIVITY_IN_TGC}=true&${GET_PARAMS.WORKSHEET_ID}=${worksheetId}&${GET_PARAMS.SKIP_ONBOARDING}=${String(skipOnboarding)}`;

  if (utm && utm.charAt() === '?') utm = utm.substring(1);

  window.location = `${page}${params}&${utm}`;
};

export const redirectToTgcWithOpenActivityParams = ({activityId, activityType, utm}) => {
  const page = `${URLS.TG_ONLINE}`;
  const params = activityType && activityId && `?${GET_PARAMS.OPEN_ACTIVITY_IN_TGC}=${activityType}_${activityId}&${GET_PARAMS.SKIP_ONBOARDING}=true`;
  if (utm && utm.charAt() === '?') utm = utm.substring(1);

  window.location = `${page}${params}&${utm}`;
};

export const redirectToTgcWithOpenLessonParams = ({ gradeId, subjectId, lessonId, utm}) => {
  const page = `${URLS.TG_ONLINE}`;
  const params = gradeId && subjectId && lessonId && `?${GET_PARAMS.OPEN_ACTIVITY_IN_TGC}=lesson_${lessonId}&${GET_PARAMS.GRADE}=${gradeId}&${GET_PARAMS.SUBJECT}=${subjectId}&${GET_PARAMS.SKIP_ONBOARDING}=true`;
  if (utm && utm.charAt() === '?') utm = utm.substring(1);

  window.location = `${page}${params}&${utm}`;
};

export const handleCompleteOnlineListener = (e, queryParams) => {
  e.preventDefault();

  const worksheetId = $(e.currentTarget).attr('data-activity-id');
  const worksheetSlug = $(e.currentTarget).attr('data-slug');

  storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_ID, worksheetId);
  storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_SLUG, worksheetSlug);
  storage.set(LOCAL_STORAGE_KEYS.PLACEMENT, PLACEMENTS.WORKSHEET_LIST_PAGE);
  storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_ACTION, WORKSHEET_ACTIONS.GO_TO_WORKSHEET_CHECK_IN_TGC);

  redirectToCompleteOnlineInTGC(worksheetSlug, queryParams);
};

export const updateWorksheetDownloadCount = () => {
  // download count/limit
  let count = 0;

  if (storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_TRY_DOWNLOADED_COUNT)) {
    count = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_TRY_DOWNLOADED_COUNT);
  }

  count = parseInt(count) + 1;
  storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_TRY_DOWNLOADED_COUNT, count);

  // last download date
  let isAbleToDownload = false;

  let worksheetLastDownloadDate = null;
  if (storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_LAST_DOWNLOAD_DATE)) {
    worksheetLastDownloadDate = storage.get(LOCAL_STORAGE_KEYS.WORKSHEET_LAST_DOWNLOAD_DATE);
  }

  const nowTimestamp = Date.now();
  const worksheetLastDownloadTimestamp = parseInt(worksheetLastDownloadDate);
  const oneDayInMilliseconds = 86400000;
  if ((nowTimestamp - worksheetLastDownloadTimestamp) > oneDayInMilliseconds) {
    // reset count/limit if one day already passed from the last download
    count = 1;
    storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_TRY_DOWNLOADED_COUNT, count);
    isAbleToDownload = true;
  }
  storage.set(LOCAL_STORAGE_KEYS.WORKSHEET_LAST_DOWNLOAD_DATE, nowTimestamp);

  return {count, isAbleToDownload};
};

/**
 * @param e {Event}
 * @param type {String}
 */
export const downloadWorksheetHandler = (e, type) => {
  e.preventDefault();

  const $target = $(e.currentTarget);
  const utm = $target.attr('data-utm');
  const worksheetId = $target.attr('data-id');

  // HAS subscription
  if (window.hasValidParentSubscription) {
    if (type === WORKSHEET_ACTION_TYPES.DOWNLOAD) {
      dispatchCustomEvent(WEBSITE_CUSTOM_EVENTS.DOWNLOAD_WORKSHEET, {worksheetId});
      return;
    }

    if (type === WORKSHEET_ACTION_TYPES.COMPLETE_ONLINE || type === WORKSHEET_ACTION_TYPES.LEARN_ONLINE) {
      const worksheetSlug = $(e.currentTarget).attr('data-slug');
      window.location = `/worksheets-check/${worksheetSlug}`;
      return;
    }
  }

  // NO subscription COMPLETE ONLINE / LEARN ONLINE btns
  if (type === WORKSHEET_ACTION_TYPES.COMPLETE_ONLINE || type === WORKSHEET_ACTION_TYPES.LEARN_ONLINE) {
    redirectToTgcWithOpenActivityParams({activityId: worksheetId, activityType: 'worksheet', utm});
    return;
  }

  // NO subscription DOWNLOAD
  if (type === WORKSHEET_ACTION_TYPES.DOWNLOAD) {
    const {count, isAbleToDownload} = updateWorksheetDownloadCount();
    $(MODALS.WEBSITE_PLANS_MODAL).addClass(IS_DOWNLOAD_WORKSHEET_FLOW);

    const handleShowModal = () => {
      if ($(MODALS.WORKSHEET_IMAGE_POPUP).is(':visible')) {
        $(MODALS.WORKSHEET_IMAGE_POPUP).modal('hide');
        $(MODALS.WORKSHEET_IMAGE_POPUP).on('hidden.bs.modal', function(){
          $(MODALS.WEBSITE_PLANS_MODAL).modal('show');
          $(MODALS.WORKSHEET_IMAGE_POPUP).off('hidden.bs.modal');
        });

        return;
      }

      $(MODALS.WEBSITE_PLANS_MODAL).modal('show');
      return;
    };

    if (count <= 2 || isAbleToDownload) {
      const worksheetUtm = e.target.dataset.utm;

      // show website-plans modal with 3 plans

      $(MODALS.WEBSITE_PLANS_MODAL).attr('data-worksheet-id', worksheetId);
      $(MODALS.WEBSITE_PLANS_MODAL).attr('data-worksheet-utm', worksheetUtm);

      handleShowModal();
      return;
    }

    if (count > 2 || !isAbleToDownload) {
      // show website-plans modal with 2 plans
      const twoPlansClassName = '_two-plans';
      const $plansContainer = $('[data-selector="website-plans-modal-plans"]');
      $plansContainer.addClass(twoPlansClassName);

      const limitModalTitle = 'You\'ve reached your daily download limit!';
      const $websitePlansModalTitle = $('[data-selector="website-plans-modal-title"]');
      $websitePlansModalTitle.html(limitModalTitle);

      handleShowModal();
      return;
    }
  }
};
